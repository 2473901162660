





























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import type { Currency, Network } from '@/types'
import { BigNumber } from 'bignumber.js'
import { getTransferOutFee, NFTTransferOutOrGetNFTFee, TransferOutFee } from '@/clients/cpinblocks'
import type { WithdrawalType } from '@/clients/cpinblocks'
import { Fee } from '@/models/Fee'
import { eventBus } from '@/utils'

@Component
export default class FeeSelector extends Vue {
	@Prop({ default: -1 }) precision!: number
	@Prop() currency!: Currency
	@Prop({ default: false }) disabled!: boolean | string
	@Prop() label?: string
	@Prop() outlined?: boolean
	@Prop() required?: boolean
	@Prop() onClearInterval?: boolean
	@Prop({ default: 15 }) countdownDuration!: number
	@Prop({ default: true }) autoRefresh!: boolean
	@Prop({ default: 60 }) preserveDelay!: number
	// FIXME
	@Prop({ default: 'BINANCE' }) network!: Network
	@Prop({ default: 0 }) amount!: BigNumber
	@Prop({ default: 'CURRENCY' }) productType!: WithdrawalType
	@Prop({ default: null }) nftDetails!: Record<string, string>
	@Prop({ default: false }) errorMessage!: boolean

	fee: TransferOutFee | null = null
	loading = true
	selectedFee: any | null = null
	feeValue = new BigNumber(0)
	readonly = true
	timer?: ReturnType<typeof setTimeout>
	timeLeftSeconds: number | null = null

	@Watch('autoRefresh')
	async restoreAutoRefresh (value: boolean): Promise<void> {
		if (value) {
			await this.reset()
		} else {
			if (this.timer) {
				clearInterval(this.timer)
			}
			this.clearAllIntervals()
		}
	}

	@Watch('nftDetails', { immediate: true })
	async refreshFee (value: Record<string, string>): Promise<void> {
		if (value?.to) {
			await this.reset()
		} else {
			if (this.timer) {
				clearInterval(this.timer)
			}
			this.clearAllIntervals()
		}
	}

	get displayedLabel (): string | undefined {
		let result = this.label
		if (this.timeLeftSeconds && this.timeLeftSeconds > 0 && this.countdownDuration > this.timeLeftSeconds) {
			result += ' (auto refresh in ' + this.timeLeftSeconds.toFixed() + ' seconds)'
		}
		return result
	}

	async created (): Promise<void> {
		await this.reset()
		eventBus.$on('updateFee', this.reset)
	}

	get feeRate (): TransferOutFee | null {
		return this.fee ?? null
	}

	async reset (): Promise<void> {
		this.loading = true
		this.$emit('loading', true)
		if (this.productType === 'CURRENCY') {
			this.fee = await getTransferOutFee(this.$store.state.jwt, this.currency, this.network, this.amount)
		} else {
			// Send request to get fee for transferring nft only when the user has chosen the wallet address
			if (this.nftDetails.to) {
				this.fee = await NFTTransferOutOrGetNFTFee(this.$store.state.jwt, '234568', {
					nft: {
						collection: this.nftDetails.collection.toUpperCase(),
						tokenId: this.nftDetails.tokenId,
						supply: 1,
						maxSupply: 1,
					},
					network: this.nftDetails.network,
					to: this.nftDetails.to,
				}, true)
			}
		}
		this.readonly = this.fee?.fees.length === 1
		this.timer = setInterval(this.refreshTimeLeft, 500)
		this.$emit('intervalId', this.timer)
		if (this.fee) {
			this.selectedFee = this.fee?.fees[0]
			this.selectedFee.expiresAt = this.fee?.expiresAt
			this.selectedFee.id = this.fee?.feeId
			this.$emit('change', this.selectedFee)
		}
		this.loading = false
		this.$emit('loading', false)
	}

	isDateInThePast (feeExpiresAt: string): boolean {
		const now = new Date().getTime()
		const feeExpirationTimeMs = new Date(feeExpiresAt).getTime()
		if (feeExpirationTimeMs < now) {
			return true
		}
		return false
	}

	// WTF is that ?
	clearAllIntervals (): void {
		for (let index = 0; index < 300; index++) {
			clearInterval(index)
		}
	}

	// Terrible implementation
	async refreshTimeLeft (): Promise<void> {
		if (this.fee) {
			this.timeLeftSeconds = (new Date(this.fee?.expiresAt).getTime() - new Date().getTime()) / 1000 - this.preserveDelay
			if (this.timeLeftSeconds > 60) {
				this.timeLeftSeconds = 60
			}
			if (this.isDateInThePast(this.fee.expiresAt) || this.timeLeftSeconds < 0.59) {
				this.timer && clearInterval(this.timer)
				this.clearAllIntervals()
				if (this.autoRefresh) {
					this.selectedFee = null
					await this.reset()
				}
			}
		}
	}

	async onChange () : Promise<void> {
		const result: Fee = {
			currency: this.selectedFee.currency,
			expiresAt: this.selectedFee.expiresAt,
			id: this.selectedFee.feeId,
			type: this.selectedFee.type,
			value: new BigNumber(this.selectedFee.value),
		} as Fee
		this.$emit('change', result)
	}

	beforeDestroy (): void {
		if (this.timer) {
			clearInterval(this.timer)
		}
		this.clearAllIntervals()
	}
}
