

























































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import AddWithdrawal from '@/components/AddWithdrawal.vue'
import DepositInformation from '@/components/DepositInformation.vue'
import {getAccounts, getAPIConfiguration, faucet, burn, TradePair, getSpotSummary} from '@/clients/cpinblocks'
import { Account } from '@/models/Account'
import { BigNumber } from 'bignumber.js'
import type { Currency, TokenType } from '@/types'
import { Conf, CurrencyType, TransferableConf } from '@/models/Conf'
import { AccountCharacteristics, appEnv, env, getGlobalConfInfo, guessAllTokensType } from '@/utils'
import AddInternalTransfer from '@/components/AddInternalTransfer.vue'
import AddCheckout from '@/components/AddCheckout.vue'
import AddWireTransferEUR from '@/components/AddWireTransferEUR.vue'
import Transak from '@/components/Transak.vue'

@Component({
  components: {
    AddCheckout,
    AddInternalTransfer,
    AddWireTransferEUR,
    AddWithdrawal,
    DepositInformation,
    Transak,
  },
})
export default class Home extends Vue {
  private account: Account | null = null
  private accounts: Account[] = []
  private allAccounts: Account[] = []
  private allAccountsCharacteristics: AccountCharacteristics[] = []
  private checkoutCurrency: string | null = null
  private checkoutType: string | null = null
  private conf: Conf | null = null
  private depositCurrency: Currency | null = null
  private fiatAccounts: Account[] = []
  private headersExistingAccounts = [
    {
      align: 'left',
      sortable: false,
      value: 'currency',
      text: 'Currency',
      width: '15%',
    },
    {
      align: 'right',
      sortable: false,
      value: 'balance',
      text: 'Balance',
      width: '15%',
    },
    {
      align: 'right',
      sortable: false,
      value: 'actions',
      text: 'Actions',
      width: '70%',
    },
  ]
  private headersNotExistingAccounts = [
    {
      align: 'left',
      sortable: false,
      value: 'currency',
      text: 'Currency',
      width: '40%',
    },
    {
      align: 'right',
      sortable: false,
      value: 'actions',
      text: 'Actions',
      width: '60%',
    },
  ]
  private addId = 0
  private optionAccounts: Account[] = []
  private ibxeAccount: Account[] = []
  private inAppAccounts: Account[] = []
  private isExpandedOptionAccounts = false
  private isExpandedMyAccounts = true
  private isExpandedSavingAccounts = false
  private isExpandedNFTAccounts = false
  private isExpandedInAppAccounts = false
  private isExpandedPossibleAccounts = true
  private isTransferDialogVisible = false
  private loading = false
  private nftAccounts: Account[] = []
  // TODO: find a way to get this list or to induct it from the configuration
  private nftCurrencies: string[] = [ 'CRYPTOPOLITICS', 'TOONPET' ]
  private possibleAccounts: Account[] = []
  private savingAccounts: Account[] = []
  private showCheckout = false
  private showDeposit = false
  private showTransak = false
  private showWireTransferEUR = false
  private showWithdrawal = false
  private showWithdrawalEUR = false
  private spotPairs: TradePair[] = []
  private transakCurrency = 'USDT'
  private transferCurrency: Currency | null = null
  private transferType: TokenType | null = null
  private withdrawalCurrency: Currency | null = null

  private balance (currency: Currency, type: TokenType): BigNumber | null {
    for (const a of this.allAccounts) {
      if (a.currency === currency && a.type === type) {
        return a.balance
      }
    }
    return null
  }

  get kyc2fa (): boolean {
    return this.$store.getters.kyc2fa
  }

  private async mounted (): Promise<void> {
    this.conf = await getAPIConfiguration()
    await this.refresh()
    this.loading = false
  }

  private getAccountFromCurrencyType (accounts: Account[], currency: string, type: string): Account | null {
    for (const a of accounts) {
      if (a.currency === currency && a.type === type) {
        return a
      }
    }
    return null
  }

  private getAccountCharacteristicsFromCurrencyType (accounts: AccountCharacteristics[], currency: string, type: string): AccountCharacteristics | null {
    for (const a of accounts) {
      if (a.currency === currency && a.type === type) {
        return a
      }
    }
    return null
  }

  private async refresh (): Promise<void> {
    this.loading = true
    this.allAccounts = (await getAccounts(this.$store.state.jwt, false))
    this.spotPairs = await getSpotSummary(this.$store.state.jwt)
    this.conf = await getAPIConfiguration()
    this.allAccountsCharacteristics = []
    if (this.conf) {
      const tokenList = guessAllTokensType(this.conf, this.allAccounts, [], [])
      for (const c of tokenList.tokens) {
        const infos = getGlobalConfInfo(this.conf, [], [], c)
        if (infos) {
          for (const i of infos) {
            this.allAccountsCharacteristics.push(i)
          }
        }
      }
    }

    const added: CurrencyType[] = []
    this.optionAccounts = []
    this.fiatAccounts = []
    this.ibxeAccount = []
    this.accounts = []
    this.savingAccounts = []
    this.nftAccounts = []
    this.inAppAccounts = []
    this.possibleAccounts = []

    for (const acc of this.allAccounts) {
      const characteristics = this.getAccountCharacteristicsFromCurrencyType(this.allAccountsCharacteristics, acc.currency, acc.type)
      if (characteristics === null) {
        return
      }
      if (acc.type === 'OPTION') {
        this.optionAccounts.push(acc)
      } else if (this.nftCurrencies.indexOf(characteristics.currency) >= 0) {
        this.nftAccounts.push(acc)
        added.push({ currency: acc.currency as Currency, type: acc.type as TokenType })
      } else {
        if (acc.type === 'LONG') {
          this.savingAccounts.push(acc)
          added.push({ currency: acc.currency as Currency, type: acc.type as TokenType })
        } else {
          const account = this.getAccountCharacteristicsFromCurrencyType(this.allAccountsCharacteristics, acc.currency, 'MAIN')
          if (acc.currency === 'EUR' && acc?.type === 'MAIN') {
            this.fiatAccounts.push(acc)
            added.push({ currency: acc.currency as Currency, type: acc.type as TokenType })
          } else if (account?.address === null) {
            this.inAppAccounts.push(acc)
            added.push({ currency: acc.currency as Currency, type: acc.type as TokenType })
          } else {
            if (acc.tokenType === 'FUNGIBLE') {
              const characteristics2 = this.getAccountCharacteristicsFromCurrencyType(this.allAccountsCharacteristics, acc.currency, 'MAIN')
              if (characteristics2?.address) {
                this.accounts.push(acc)
                added.push({ currency: acc.currency as Currency, type: acc.type as TokenType })
              } else {
                // no SC on MAIN account => in app currency
                this.inAppAccounts.push(acc)
                added.push({ currency: acc.currency as Currency, type: acc.type as TokenType })
              }
            } else {
              this.nftAccounts.push(acc)
              added.push({ currency: acc.currency as Currency, type: acc.type as TokenType })
            }
          }
        }
      }
    }
    for (const acc of this.allAccountsCharacteristics) {
      if (acc.type !== 'MAIN') {
        continue
      }
      if (this.nftCurrencies.indexOf(acc.currency.toString()) >= 0) {
        continue
      }
      let cont = false
      for (const ct of added) {
        if (ct.currency === acc.currency && ct.type === acc.type) {
          cont = true
        }
      }
      if (cont) {
        continue
      }
      if ((acc.address && acc.deposit === true) || appEnv() !== 'prod'){
        this.possibleAccounts.push({
          id: acc.currency,
          name: '',
          currency: acc.currency,
          type: 'MAIN',
          balance: new BigNumber('0'),
          tokenType: 'FUNGIBLE',
          inventory: [],
          operations: [],
        })
        added.push({ currency: acc.currency as Currency, type: acc.type as TokenType })
        added.push({ currency: acc.currency as Currency, type: 'MAIN' as TokenType })
      }
    }
    this.loading = false
  }

  private transferVisible (item: any): boolean {
    if (this.conf) {
      const transferableToken = this.conf.transferable?.find((token: TransferableConf) => item.type === token.type && item.currency === token.currency)
      if (transferableToken) {
        if (transferableToken.whitelist && transferableToken.whitelist.length > 0) {
          return transferableToken.whitelist.indexOf(this.$store.state.owner.ownerId) >= 0
        } else {
          return true
        }
      }
    }
    return false
  }

  private transferEnabled (item: Account): boolean {
    return (this.$store.state.owner.locked !== 'SOFT' || this.$store.state.owner.locked !== 'HARD') && this.kyc2fa && this.transferVisible(item) && new BigNumber(item.balance).gt(0)
  }

  private depositVisible (item: any): boolean {
    const res = this.getAccountCharacteristicsFromCurrencyType(this.allAccountsCharacteristics, item.currency, item.type)
    if (res) {
      return res.deposit ? res.deposit : false
    }
    return false
  }

  private withdrawalEUREnabled (item: any): boolean {
    return this.kyc2fa && this.$store.state.owner.ibans && this.$store.state.owner.ibans.length > 0
  }

  get appEnv (): string {
    return env('VUE_APP_ENV')
  }

  private depositEnabled (item: any): boolean {
    return this.depositVisible(item)
  }

  private checkoutEnabled (item: any): boolean {
    if (item.currency === 'IBXE') {
      return true
    }
    for (const tp of this.spotPairs) {
      if (tp.productCurrency === item.currency &&
          tp.unitPriceCurrency === 'IBXE' &&
          tp.unitPriceType === 'MAIN' &&
          tp.checkout === 'ENABLED') {
        return true
      }
    }
    return false
  }

  // TODO: remove "appEnv !== 'prod'" when ready for production
  private transakVisible (item: any): boolean {
    return this.appEnv === 'staging' && item.currency === 'USDT' && item.type === 'MAIN'
  }

  private faucetEnabled (item: any): boolean {
    return this.faucetVisible(item)
  }

  private faucetVisible (item: any): boolean {
    return this.appEnv !== 'prod' && (item.currency.split("\.").length > 0 || item.tokenType === 'FUNGIBLE')
  }

  async activateFaucet (item: any): Promise<void> {
    const t = item.type ? item.type : 'MAIN'
    await faucet(this.$store.state.jwt, item.currency, t)
    let needLong = true
    for (const a of this.accounts) {
      if (a.currency === item.currency && a.type === 'LONG') {
        needLong = false
      }
    }
    if (needLong && ['ALMA', 'BOBY', 'HILO', 'PET', 'RECAP', 'NTS'].indexOf(item.currency) >= 0) {
      await faucet(this.$store.state.jwt, item.currency, 'LONG')
    }
    await this.refresh()
  }

  async activateBurn (item: any): Promise<void> {
    await burn(this.$store.state.jwt, item.currency, item.type)
    await this.refresh()
  }

  private withdrawVisible (item: any): boolean {
    const res = this.getAccountCharacteristicsFromCurrencyType(this.allAccountsCharacteristics, item.currency, item.type)
    if (res) {
      return res.withdraw ? res.withdraw : false
    }
    return false
  }

  private withdrawEnabled (item: any): boolean {
    if (this.$store.state.owner.locked === 'SOFT' || this.$store.state.owner.locked === 'HARD' || !this.kyc2fa) {
      return false
    }
    return true
  }

  private openIBXEWebsite (): void {
    window.open('https://ibxe.io', '_blank')
  }

  private clickOnRow (item: any): void {
    if (item.currency === 'IBXE' && item.type === 'MAIN' && this.balance(item.currency, item.type) === null) {
      this.openIBXEWebsite()
    }
    this.$router.push(`/accounts/${item.currency.toLowerCase()}/${item.type.toLowerCase()}`)
  }

  private onDeposit (item: any): void {
    this.depositCurrency = item.currency
    this.showDeposit = true
  }

  private onWithdraw (item: any): void {
    this.account = item as Account
    this.withdrawalCurrency = item.currency
    this.showWithdrawal = true
  }

  private onTransfer (item: any): void {
    this.transferCurrency = item.currency
    this.transferType = item.type
    this.isTransferDialogVisible = true
  }

  async onSuccessTransfer (): Promise<void> {
    this.addId++
    this.isTransferDialogVisible = false
    await this.refresh()
  }

  async onSuccessWithdrawalEUR (): Promise<void> {
    this.addId++
    this.showWithdrawalEUR = false
    await this.refresh()
  }

  async onSuccessWireTransferEUR (): Promise<void> {
    this.addId++
    this.showWireTransferEUR = false
    await this.refresh()
  }
}
