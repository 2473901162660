











































































































































































import type { WithdrawalType } from '@/clients/cpinblocks'
import { Address, getAccounts, getAPIConfiguration, NFTTransferOutOrGetNFTFee, withdraw } from '@/clients/cpinblocks'
import { Account } from '@/models/Account'
import { Withdraw } from '@/models/Withdraw'
import { Conf } from '@/models/Conf'
import Code2FADialog from '@/components/Code2FADialog.vue'
import CurrencyInput from '@/components/CurrencyInput.vue'
import FeeSelector from '@/components/FeeSelector.vue'
import { alertError, alertRawError, convertValueToBigNumber, env, eventBus, formatFixedDecimalsNice } from '@/utils'
import { BigNumber } from 'bignumber.js'
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { Currency, Network, TokenType, ValidationRule } from '@/types'
import { Fee } from '@/models/Fee'
import CodeInput from '@/components/CodeInput.vue'
import { TranslateResult } from 'vue-i18n'
import ModalNotification from '@/components/ModalNotification.vue'

@Component({
	components: {
		CodeInput,
		CurrencyInput,
		Code2FADialog,
		FeeSelector,
		ModalNotification,
	},
})
export default class AddWithdrawal extends Vue {
	@Prop() balance!: BigNumber
	@Prop() currency!: Currency | Record<string, string>
	@Prop({ default: 'CURRENCY' }) type!: WithdrawalType
	@Prop({ default: 'CRYPTOPOLITICS' }) collection!: string

	accounts: Account[] = []
	isValid = false
	addresses: Address[] = []
	show2FADialog = false
	conf: Conf | null = null
	amountPrecision = 18
	creating = false
	loading = true
	loadingFee = true
	amount: BigNumber = new BigNumber(0)
	to: string | undefined = ''
	fee: Fee | null = null
	// FIXME
	interval: ReturnType<typeof setTimeout> | null = null
	amountRules: ValidationRule = []
	expiresAt: string | null = null
	network: Network = 'BINANCE'
	isSuccessNotificationVisible = false
	insufficientFundsErrorMessage = ''

	// FIXME: Temporary hardcoded feature to allow unchecked destination address
	get expertIBExIds (): string[] {
		if (this.appEnv === 'prod') {
			return ['314c75d5']
		} else if (this.appEnv === 'testnet') {
			return ['f2512e78']
		} else {
			return ['6ba26e6e']
		}
	}

	get userWallets (): Address[] {
		const result: Address[] = []
		for (const a of this.addresses) {
			if (a.enabled) {
				const b = a
				if (b.name) {
					b.name = b.value + ' - ' + b.name
				} else {
					b.name = b.value
				}
				result.push(b)
			}
		}
		return result
	}

	// FIXME
	get availableBlockchains (): Record<string, string | number>[] {
    if (this.currency === 'USDC') {
      return [{
        chain: this.appEnv === 'prod' ? 42161 : 11155111,
        name: 'ARBITRUM',
      }]
    } else if (this.type === 'CURRENCY') {
      return [{
        chain: 97,
        name: 'BSC',
      }]
    } else {
      return [{
        chain: 1,
        name: 'ETHEREUM',
      }]
    }
	}

	get appEnv (): string {
		return env('VUE_APP_ENV')
	}

	get currentNetwork (): Network {
    if (this.currency === 'USDC') {
      return 'ARBITRUM'
    } else if (this.type === 'CURRENCY') {
			return 'BINANCE'
		} else {
			return this.appEnv === 'prod' ? 'ETHEREUM' : 'ETHEREUM_GOERLI'
		}
	}

	get currentBlockchain (): number {
		return this.type === 'CURRENCY' ? 97 : 1
	}

	get actionButtonTitle (): TranslateResult {
		return this.type === 'CURRENCY' ? this.$t('addWithdrawal.action.withdraw') : this.$t('addWithdrawal.action.transferOut')
	}

	get addWithdrawalTitle (): TranslateResult {
		return this.type === 'CURRENCY' ? this.$t('addWithdrawal.title.withdraw', { currency: typeof this.currency === 'string' ? this.currency : this.currency.name }) : this.$t('addWithdrawal.title.transferOut', { currency: typeof this.currency === 'string' ? this.currency : this.currency.name })
	}

	get autoRefresh (): boolean {
		return this.show2FADialog !== true
	}

	get nftDetails (): Record<string, string> {
		return {
			collection: this.collection,
			tokenId: typeof this.currency === 'object' ? this.currency.tokenId : '',
			to: this.to as string,
			network: this.currentNetwork,
		}
	}

	get account (): Account | null {
		for (const a of this.accounts) {
			if (a.currency === 'IBXE' && a.type === 'MAIN') {
				return a
			}
		}
		return null
	}

	get isLackOfFundsNotificationVisible (): boolean {
		if (this.fee === null || !this.fee.value) {
			return false
		}
		if (this.account === null) {
			return true
		}
		if (new BigNumber(this.account.balance).isGreaterThanOrEqualTo(this.fee.value)) {
			return false
		}
		return true
	}

	onWalletSelect (selected: string): void {
		this.to = selected
	}

	computeAmountRules (): void {
		this.amountRules = [
			(v?: string) => !!v || this.$t('rule.requiredField'),
			(v: string) => !convertValueToBigNumber(v).isNaN() || this.$t('rule.requiredField'),
			(v: string) => {
				return convertValueToBigNumber(v).isLessThanOrEqualTo(this.balance) || this.$t('addOffer.rule.max', {
					max: formatFixedDecimalsNice(this.$i18n, this.balance, this.amountPrecision),
				})
			},
		]
		this.validateForm()
	}

	onChangeFee (fee: Fee | null): void {
		if (fee) {
			this.fee = fee
			this.expiresAt = fee.expiresAt
			this.computeAmountRules()
		}
	}

	onLoadingFee (loading: boolean): void {
		this.loadingFee = loading
	}

	async onUpdateAmount (): Promise<void> {
		if (this.interval) clearTimeout(this.interval)
		this.loading = true
		this.interval = setTimeout(() => {
			eventBus.$emit('updateFee')
			this.loading = false
		}, 1000)
	}

	async created (): Promise<void> {
		this.loading = true
		this.conf = await getAPIConfiguration()
		this.accounts = await getAccounts(this.$store.state.jwt, false)
		this.addresses = this.$store.state.owner?.ethereum?.addresses ?? []
		// we want to force the user to select the right wallet even if the user has only 1 wallet
		this.to = undefined
		this.amount = new BigNumber('0')
		this.computeAmountRules()
		this.loading = false
	}

	async cancel (): Promise<void> {
		this.$emit('cancel')
	}

	open2FADialog (): void {
		this.creating = true
		this.show2FADialog = true
	}

	async onTransferOutClick (): Promise<void> {
		if (this.type === 'NFT') {
			if (this.fee) {
				this.creating = true
				if (!this.isLackOfFundsNotificationVisible) {
					this.open2FADialog()
				}
				this.creating = false
			} else {
				alertError('Something went wrong, try to ask for a new fee price')
			}
			return
		}
		this.open2FADialog()
	}

	onSuccessNotificationClose (): void {
		this.$emit('success')
		this.isSuccessNotificationVisible = false
	}

	async createWithdrawOperation (object: any): Promise<void> {
		const code2FA = object.code
		this.show2FADialog = false
		this.creating = true
		try {
			const params: Withdraw = {
				id: Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10),
				currency: this.currency as Currency,
				amount: this.amount.toString(),
				fee: {
					currency: this.fee?.currency as Currency,
					type: this.fee?.type as TokenType,
					value: this.fee?.value.toString() ?? '',
				},
				network: this.currentNetwork,
				to: this.to,
			}
			if (!this.fee) {
				alertRawError('Error during withdraw operation, please try again later.')
				this.$emit('fail')
				return
			} else {
				if (this.type === 'CURRENCY') {
					await withdraw(this.$store.state.jwt, code2FA, this.fee.id, params)
					this.$emit('success')
				} else {
					await NFTTransferOutOrGetNFTFee(this.$store.state.jwt, code2FA, {
						nft: {
							collection: this.nftDetails.collection.toUpperCase(),
							tokenId: this.nftDetails.tokenId,
							supply: 1,
							maxSupply: 1,
						},
						network: this.currentNetwork,
						to: this.nftDetails.to,
						feeId: this.fee.id,
						fee: this.fee,
						// feeAmount: this.fee.value,
						id: Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10),
					}, false)
					this.isSuccessNotificationVisible = true
				}
			}
		} catch (error: any) {
			alertRawError(error.message)
			this.creating = false
			this.$emit('fail')
			return
		}
		this.creating = false
	}

	validateForm (): void {
		(this.$refs.form as Vue & { validate: () => boolean }).validate()
	}
}
